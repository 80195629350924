<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card>
          <div slot="header">
            Exports
            <b-button class="ml-3" size="sm" v-on:click="loadJobs" variant="primary">Refresh</b-button>
          </div>

          <b-spinner small v-show="loading" label="Spinning"></b-spinner>

          <div v-show="!loading && (exportJobs.length == 0)">
            No exports found
          </div>

          <b-pagination
            v-show="(exportJobs.length > perPage)"
            v-model="page"
            :total-rows="exportJobs.length"
            :per-page="perPage"
            align="fill"
            size="sm"></b-pagination>

          <b-table 
              small
              v-show="!loading && (exportJobs.length > 0)"
              :current-page="page"
              :fields="fields"
              sort-by="started"
              :sort-desc="true"
              :per-page="perPage"
              :items="exportJobs">

            <template v-slot:cell(started)="data">
              {{ moment(data.item.started).calendar() }}<br>
            </template>

            <template v-slot:cell(status)="data">
              {{ statusTexts[data.item.status] }}<br>
            </template>

            <template v-slot:cell(filesize)="data">
              {{ numeral(data.item.filesize).format('0.0 b') }}<br>
            </template>

            <template v-slot:cell(actions)="data">
              <b-button v-if="data.item.status == 'done'" variant="primary" size="sm" :href="'/api/users/me/export-files/' + data.item.id">Download</b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import moment from 'moment'
  import numeral from 'numeral'

  export default {
    name: 'Exports',
    components: {

    },
    data () {
      return {
        exportJobs: [],
        page: 1,
        statusTexts: {
          done: "Done",
          running: "Running"
        },
        fields: [
          {
            key: 'started',
            label: 'Date/time',
            //sortable: true
          },
          {
            key: 'filename',
            label: 'Filename'
          },
          {
            key: 'filesize',
            label: 'File size'
          },
          {
            key: 'status',
          },
          {
            key: 'actions',
            label: ''
          },
        ],
        loading: true,
        perPage: 10,
      }
    },
    methods: {
      moment: moment,
      numeral: numeral,
      loadJobs () {
        this.loading = true
        this.exportJobs = []
        this.$http
          .get('/users/me/jobs')
          .then(response => {
            this.exportJobs = response.data
            this.loading = false
          })
      }
    },
    mounted () {
      this.loadJobs()

      this.$root.$data.crumbs = [
        {to: { name: 'exports' }, text: 'Exports`'}
      ]
    }
  }
</script>

<style scoped>

</style>
